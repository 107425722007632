// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  switch (str) {
    case "GRAMMAR_AND_SPELLING" :
        return "GRAMMAR_AND_SPELLING";
    case "IMAGE_ALT_TEXT" :
        return "IMAGE_ALT_TEXT";
    case "SUGGESTION" :
        return "SUGGESTION";
    default:
      return ;
  }
}

function serialize(s) {
  return s;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
