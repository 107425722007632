// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RelayRouter__Utils from "rescript-relay-router/src/RelayRouter__Utils.mjs";
import * as RelayRouter__Bindings from "rescript-relay-router/src/RelayRouter__Bindings.mjs";
import * as RelayRouter__Internal from "rescript-relay-router/src/RelayRouter__Internal.mjs";

function makePrepareProps(environment, pathParams, queryParams, $$location) {
  return {
          environment: environment,
          location: $$location
        };
}

var Internal = {
  makePrepareProps: makePrepareProps
};

function makeLink() {
  return RelayRouter__Bindings.generatePath("/signup", Js_dict.fromArray([]));
}

function isRouteActive($staropt$star, param) {
  var exact = $staropt$star !== undefined ? $staropt$star : false;
  return Belt_Option.isSome(RelayRouter__Internal.matchPathWithOptions({
                  path: "/signup",
                  end: exact
                }, param.pathname));
}

function useIsRouteActive(exactOpt) {
  var exact = exactOpt !== undefined ? exactOpt : false;
  var $$location = RelayRouter__Utils.useLocation();
  return React.useMemo((function () {
                return isRouteActive(exact, $$location);
              }), [
              $$location,
              exact
            ]);
}

export {
  Internal ,
  makeLink ,
  isRouteActive ,
  useIsRouteActive ,
}
/* react Not a pure module */
