// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "IMAGE_ALT_TEXT" || str === "GRAMMAR_AND_SPELLING" || str === "SUGGESTION") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

var defaultValue = "IMAGE_ALT_TEXT";

export {
  defaultValue ,
  parse ,
  serialize ,
}
/* No side effect */
