// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ClerkReact from "@clerk/clerk-react";

var Provider = {};

var SignedIn = {};

var SignedOut = {};

var SignInButton = {};

var SignOutButton = {};

var UserButton = {};

var Pagination = {};

var Hook = {};

function use(prim) {
  return ClerkReact.useUser();
}

var User = {
  Hook: Hook,
  use: use
};

var getTokenRef = {
  contents: undefined
};

var Auth = {
  getTokenRef: getTokenRef
};

var Organization = {};

var OrganizationList = {};

var Hooks = {
  Auth: Auth,
  Organization: Organization,
  OrganizationList: OrganizationList
};

var OrganizationSwitcher = {};

var SignIn = {};

var SignUp = {};

export {
  Provider ,
  SignedIn ,
  SignedOut ,
  SignInButton ,
  SignOutButton ,
  UserButton ,
  Pagination ,
  User ,
  Hooks ,
  OrganizationSwitcher ,
  SignIn ,
  SignUp ,
}
/* @clerk/clerk-react Not a pure module */
