// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LucideReact from "lucide-react";
import * as TailwindMerge from "tailwind-merge";
import * as JsxRuntime from "react/jsx-runtime";

function LoadingView(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(LucideReact.Loader2, {
                            className: "w-10 h-10 animate-spin text-brand"
                          }),
                      JsxRuntime.jsx("span", {
                            children: "Loading"
                          })
                    ],
                    className: TailwindMerge.twMerge(["flex flex-col items-center justify-center h-full"])
                  }),
              className: "flex-1 h-full"
            });
}

var make = LoadingView;

export {
  make ,
}
/* lucide-react Not a pure module */
