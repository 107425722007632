// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.mjs";
import * as Clerk from "./utils/Clerk.mjs";
import * as React from "react";
import * as RelayEnv from "./RelayEnv.mjs";
import * as SignInPage from "./pages/SignInPage.mjs";
import * as SignUpPage from "./pages/SignUpPage.mjs";
import * as LoadingView from "./pages/LoadingView.mjs";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as RouteDeclarations from "./routes/__generated__/RouteDeclarations.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as ClerkReact from "@clerk/clerk-react";
import * as RelayRouter__Utils from "rescript-relay-router/src/RelayRouter__Utils.mjs";
import * as ReactDOMExperimental from "rescript-relay/src/ReactDOMExperimental.mjs";
import * as Route__Root__SignIn_route from "./routes/__generated__/Route__Root__SignIn_route.mjs";
import * as Route__Root__SignUp_route from "./routes/__generated__/Route__Root__SignUp_route.mjs";
import * as RelayRouter__AssetPreloader from "rescript-relay-router/src/RelayRouter__AssetPreloader.mjs";

function EntryClient$SignedOut(props) {
  var isSignedInRoute = Route__Root__SignIn_route.useIsRouteActive(undefined);
  var isSignedUpRoute = Route__Root__SignUp_route.useIsRouteActive(undefined);
  var match = RelayRouter__Utils.useRouter();
  var replace = match.replace;
  var isAuthPath = isSignedInRoute || isSignedUpRoute;
  React.useEffect((function () {
          if (!isAuthPath) {
            replace(Route__Root__SignIn_route.makeLink(), undefined);
          }
          
        }), []);
  if (isSignedInRoute) {
    return JsxRuntime.jsx(SignInPage.make, {});
  } else if (isSignedUpRoute) {
    return JsxRuntime.jsx(SignUpPage.make, {});
  } else {
    return JsxRuntime.jsx(LoadingView.make, {});
  }
}

var SignedOut = {
  make: EntryClient$SignedOut
};

function EntryClient$EntryPoint(props) {
  var match = ClerkReact.useAuth();
  var getToken = match.getToken;
  React.useEffect((function () {
          Clerk.Hooks.Auth.getTokenRef.contents = getToken;
        }), [getToken]);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ClerkReact.SignedOut, {
                      children: JsxRuntime.jsx(EntryClient$SignedOut, {})
                    }),
                JsxRuntime.jsx(ClerkReact.SignedIn, {
                      children: JsxRuntime.jsx(App.make, {})
                    })
              ]
            });
}

var EntryPoint = {
  make: EntryClient$EntryPoint
};

function boot() {
  var routes = RouteDeclarations.make(undefined);
  var routerEnvironment = RelayRouter.RouterEnvironment.makeBrowserEnvironment();
  var match = RelayRouter.Router.make(routes, routerEnvironment, RelayEnv.environment, RelayRouter__AssetPreloader.makeClientAssetPreloader(RelayEnv.preparedAssetsMap));
  ReactDOMExperimental.renderConcurrentRootAtElementWithId(JsxRuntime.jsx(ClerkReact.ClerkProvider, {
            publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
            children: JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
                  environment: RelayEnv.environment,
                  children: JsxRuntime.jsx(RelayRouter.Provider.make, {
                        value: match[1],
                        children: JsxRuntime.jsx(EntryClient$EntryPoint, {})
                      })
                })
          }), "root");
}

boot();

export {
  SignedOut ,
  EntryPoint ,
  boot ,
}
/*  Not a pure module */
