// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FactTab from "../../pages/ArticleRegulationTool/FactTab.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as TaskType from "../../scalars/TaskType.mjs";
import * as SortOrder from "../../scalars/SortOrder.mjs";
import * as Timestamp from "../../scalars/Timestamp.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as TaskStatus from "../../scalars/TaskStatus.mjs";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as ArticleDetailsTab from "../../scalars/ArticleDetailsTab.mjs";
import * as TranscriptionMode from "../../scalars/TranscriptionMode.mjs";
import * as Belt_HashMapString from "rescript/lib/es6/belt_HashMapString.js";
import * as TaskQueueSortOption from "../../scalars/TaskQueueSortOption.mjs";
import * as RelayRouter__Bindings from "rescript-relay-router/src/RelayRouter__Bindings.mjs";
import * as AudioSourceSortAttribute from "../../scalars/AudioSourceSortAttribute.mjs";
import * as ArticleDetailsSuggestionsTab from "../../scalars/ArticleDetailsSuggestionsTab.mjs";
import * as RelayRouter__Internal__DeclarationsSupport from "rescript-relay-router/src/RelayRouter__Internal__DeclarationsSupport.mjs";

var loadedRouteRenderers = Belt_HashMapString.make(17);

function make(prepareDisposeTimeoutOpt) {
  var prepareDisposeTimeout = prepareDisposeTimeoutOpt !== undefined ? prepareDisposeTimeoutOpt : 300000;
  var match = RelayRouter__Internal__DeclarationsSupport.makePrepareAssets(loadedRouteRenderers, prepareDisposeTimeout);
  var prepareRoute = match.prepareRoute;
  var getPrepared = match.getPrepared;
  var routeName = "Root";
  var loadRouteRenderer = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName, loadedRouteRenderers);
  };
  var makePrepareProps = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$1 = "Root__Home";
  var loadRouteRenderer$1 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Home_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$1, loadedRouteRenderers);
  };
  var makePrepareProps$1 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$2 = "Root__SignIn";
  var loadRouteRenderer$2 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__SignIn_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$2, loadedRouteRenderers);
  };
  var makePrepareProps$2 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$3 = "Root__SignUp";
  var loadRouteRenderer$3 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__SignUp_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$3, loadedRouteRenderers);
  };
  var makePrepareProps$3 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$4 = "Root__ArticlesSearch";
  var loadRouteRenderer$4 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticlesSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$4, loadedRouteRenderers);
  };
  var makePrepareProps$4 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            selectedItemId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItemId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$5 = "Root__ArticleList";
  var loadRouteRenderer$5 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleList_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$5, loadedRouteRenderers);
  };
  var makePrepareProps$5 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$6 = "Root__ArticleFromTypo3";
  var loadRouteRenderer$6 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleFromTypo3_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$6, loadedRouteRenderers);
  };
  var makePrepareProps$6 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"]
          };
  };
  var routeName$7 = "Root__ArticleDetail";
  var loadRouteRenderer$7 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleDetail_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$7, loadedRouteRenderers);
  };
  var makePrepareProps$7 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            tab: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), (function (value) {
                    return ArticleDetailsTab.parse(decodeURIComponent(value));
                  })),
            selectedFactId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            subTab: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "subTab"), (function (value) {
                    return ArticleDetailsSuggestionsTab.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$8 = "Root__ArticleRegulationToolList";
  var loadRouteRenderer$8 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleRegulationToolList_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$8, loadedRouteRenderers);
  };
  var makePrepareProps$8 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$9 = "Root__ArticleRegulationToolDetail";
  var loadRouteRenderer$9 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleRegulationToolDetail_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$9, loadedRouteRenderers);
  };
  var makePrepareProps$9 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            selectedFactId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            tab: Belt_Option.getWithDefault(Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), (function (value) {
                        return FactTab.parse(decodeURIComponent(value));
                      })), FactTab.defaultValue)
          };
  };
  var routeName$10 = "Root__TicketsSearch";
  var loadRouteRenderer$10 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TicketsSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$10, loadedRouteRenderers);
  };
  var makePrepareProps$10 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            selectedItem: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            filterOpen: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  })),
            type_: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "type_"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            employee: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "employee"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            created_at: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "created_at"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$11 = "Root__AudioSourceList";
  var loadRouteRenderer$11 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AudioSourceList_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$11, loadedRouteRenderers);
  };
  var makePrepareProps$11 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            sortAttribute: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), (function (value) {
                    return AudioSourceSortAttribute.parse(decodeURIComponent(value));
                  })),
            sortOrder: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), (function (value) {
                    return SortOrder.parse(decodeURIComponent(value));
                  }))
          };
  };
  var routeName$12 = "Root__AudioSourceDetails";
  var loadRouteRenderer$12 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__AudioSourceDetails_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$12, loadedRouteRenderers);
  };
  var makePrepareProps$12 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            timestamp: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), (function (value) {
                    return Timestamp.parse(decodeURIComponent(value));
                  })),
            mode: Belt_Option.getWithDefault(Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "mode"), (function (value) {
                        return TranscriptionMode.parse(decodeURIComponent(value));
                      })), TranscriptionMode.defaultValue)
          };
  };
  var routeName$13 = "Root__Clippings";
  var loadRouteRenderer$13 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Clippings_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$13, loadedRouteRenderers);
  };
  var makePrepareProps$13 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$14 = "Root__ClippingDetails";
  var loadRouteRenderer$14 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ClippingDetails_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$14, loadedRouteRenderers);
  };
  var makePrepareProps$14 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"]
          };
  };
  var routeName$15 = "Root__Tasks";
  var loadRouteRenderer$15 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Tasks_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$15, loadedRouteRenderers);
  };
  var makePrepareProps$15 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            sort: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), (function (value) {
                    return TaskQueueSortOption.parse(decodeURIComponent(value));
                  })),
            taskType: Belt_Option.map(RelayRouter__Bindings.QueryParams.getArrayParamByKey(queryParams, "taskType"), (function (value) {
                    return Belt_Array.keepMap(value, (function (value) {
                                  return TaskType.parse(decodeURIComponent(value));
                                }));
                  })),
            status: Belt_Option.map(RelayRouter__Bindings.QueryParams.getArrayParamByKey(queryParams, "status"), (function (value) {
                    return Belt_Array.keepMap(value, (function (value) {
                                  return TaskStatus.parse(decodeURIComponent(value));
                                }));
                  })),
            search: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "search"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$16 = "Root__TaskDetails";
  var loadRouteRenderer$16 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TaskDetails_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$16, loadedRouteRenderers);
  };
  var makePrepareProps$16 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"]
          };
  };
  return [{
            path: "/",
            name: routeName,
            __$rescriptChunkName__: "Root_route_renderer",
            loadRouteRenderer: loadRouteRenderer,
            preloadCode: (function (environment, pathParams, queryParams, $$location) {
                return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName, loadRouteRenderer, makePrepareProps, environment, pathParams, queryParams, $$location);
              }),
            prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps, (function (pathParams, queryParams) {
                              return "Root:";
                            }), getPrepared, routeName, loadRouteRenderer, intent);
              }),
            children: [
              {
                path: "/",
                name: routeName$1,
                __$rescriptChunkName__: "Root__Home_route_renderer",
                loadRouteRenderer: loadRouteRenderer$1,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$1, loadRouteRenderer$1, makePrepareProps$1, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$1, (function (pathParams, queryParams) {
                                  return "Root__Home:";
                                }), getPrepared, routeName$1, loadRouteRenderer$1, intent);
                  }),
                children: []
              },
              {
                path: "/signin",
                name: routeName$2,
                __$rescriptChunkName__: "Root__SignIn_route_renderer",
                loadRouteRenderer: loadRouteRenderer$2,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$2, loadRouteRenderer$2, makePrepareProps$2, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$2, (function (pathParams, queryParams) {
                                  return "Root__SignIn:";
                                }), getPrepared, routeName$2, loadRouteRenderer$2, intent);
                  }),
                children: []
              },
              {
                path: "/signup",
                name: routeName$3,
                __$rescriptChunkName__: "Root__SignUp_route_renderer",
                loadRouteRenderer: loadRouteRenderer$3,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$3, loadRouteRenderer$3, makePrepareProps$3, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$3, (function (pathParams, queryParams) {
                                  return "Root__SignUp:";
                                }), getPrepared, routeName$3, loadRouteRenderer$3, intent);
                  }),
                children: []
              },
              {
                path: "/articles-search",
                name: routeName$4,
                __$rescriptChunkName__: "Root__ArticlesSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$4,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$4, loadRouteRenderer$4, makePrepareProps$4, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$4, (function (pathParams, queryParams) {
                                  return "Root__ArticlesSearch:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItemId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "");
                                }), getPrepared, routeName$4, loadRouteRenderer$4, intent);
                  }),
                children: []
              },
              {
                path: "/articles",
                name: routeName$5,
                __$rescriptChunkName__: "Root__ArticleList_route_renderer",
                loadRouteRenderer: loadRouteRenderer$5,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$5, loadRouteRenderer$5, makePrepareProps$5, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$5, (function (pathParams, queryParams) {
                                  return "Root__ArticleList:";
                                }), getPrepared, routeName$5, loadRouteRenderer$5, intent);
                  }),
                children: []
              },
              {
                path: "/article_from_typo3_id/:id",
                name: routeName$6,
                __$rescriptChunkName__: "Root__ArticleFromTypo3_route_renderer",
                loadRouteRenderer: loadRouteRenderer$6,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$6, loadRouteRenderer$6, makePrepareProps$6, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$6, (function (pathParams, queryParams) {
                                  return "Root__ArticleFromTypo3:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "");
                                }), getPrepared, routeName$6, loadRouteRenderer$6, intent);
                  }),
                children: []
              },
              {
                path: "/articles/:id",
                name: routeName$7,
                __$rescriptChunkName__: "Root__ArticleDetail_route_renderer",
                loadRouteRenderer: loadRouteRenderer$7,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$7, loadRouteRenderer$7, makePrepareProps$7, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$7, (function (pathParams, queryParams) {
                                  return "Root__ArticleDetail:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "subTab"), "");
                                }), getPrepared, routeName$7, loadRouteRenderer$7, intent);
                  }),
                children: []
              },
              {
                path: "/article-regulation-tool",
                name: routeName$8,
                __$rescriptChunkName__: "Root__ArticleRegulationToolList_route_renderer",
                loadRouteRenderer: loadRouteRenderer$8,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$8, loadRouteRenderer$8, makePrepareProps$8, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$8, (function (pathParams, queryParams) {
                                  return "Root__ArticleRegulationToolList:";
                                }), getPrepared, routeName$8, loadRouteRenderer$8, intent);
                  }),
                children: []
              },
              {
                path: "/article-regulation-tool/:id",
                name: routeName$9,
                __$rescriptChunkName__: "Root__ArticleRegulationToolDetail_route_renderer",
                loadRouteRenderer: loadRouteRenderer$9,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$9, loadRouteRenderer$9, makePrepareProps$9, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$9, (function (pathParams, queryParams) {
                                  return "Root__ArticleRegulationToolDetail:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), "");
                                }), getPrepared, routeName$9, loadRouteRenderer$9, intent);
                  }),
                children: []
              },
              {
                path: "/qa-search",
                name: routeName$10,
                __$rescriptChunkName__: "Root__TicketsSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$10,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$10, loadRouteRenderer$10, makePrepareProps$10, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$10, (function (pathParams, queryParams) {
                                  return "Root__TicketsSearch:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "type_"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "employee"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "created_at"), "");
                                }), getPrepared, routeName$10, loadRouteRenderer$10, intent);
                  }),
                children: []
              },
              {
                path: "/audios",
                name: routeName$11,
                __$rescriptChunkName__: "Root__AudioSourceList_route_renderer",
                loadRouteRenderer: loadRouteRenderer$11,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$11, loadRouteRenderer$11, makePrepareProps$11, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$11, (function (pathParams, queryParams) {
                                  return "Root__AudioSourceList:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortAttribute"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sortOrder"), "");
                                }), getPrepared, routeName$11, loadRouteRenderer$11, intent);
                  }),
                children: []
              },
              {
                path: "/audios/:id",
                name: routeName$12,
                __$rescriptChunkName__: "Root__AudioSourceDetails_route_renderer",
                loadRouteRenderer: loadRouteRenderer$12,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$12, loadRouteRenderer$12, makePrepareProps$12, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$12, (function (pathParams, queryParams) {
                                  return "Root__AudioSourceDetails:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "timestamp"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "mode"), "");
                                }), getPrepared, routeName$12, loadRouteRenderer$12, intent);
                  }),
                children: []
              },
              {
                path: "/clippings",
                name: routeName$13,
                __$rescriptChunkName__: "Root__Clippings_route_renderer",
                loadRouteRenderer: loadRouteRenderer$13,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$13, loadRouteRenderer$13, makePrepareProps$13, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$13, (function (pathParams, queryParams) {
                                  return "Root__Clippings:";
                                }), getPrepared, routeName$13, loadRouteRenderer$13, intent);
                  }),
                children: []
              },
              {
                path: "/clippings/:id",
                name: routeName$14,
                __$rescriptChunkName__: "Root__ClippingDetails_route_renderer",
                loadRouteRenderer: loadRouteRenderer$14,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$14, loadRouteRenderer$14, makePrepareProps$14, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$14, (function (pathParams, queryParams) {
                                  return "Root__ClippingDetails:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "");
                                }), getPrepared, routeName$14, loadRouteRenderer$14, intent);
                  }),
                children: []
              },
              {
                path: "/tasks",
                name: routeName$15,
                __$rescriptChunkName__: "Root__Tasks_route_renderer",
                loadRouteRenderer: loadRouteRenderer$15,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$15, loadRouteRenderer$15, makePrepareProps$15, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$15, (function (pathParams, queryParams) {
                                  return "Root__Tasks:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "sort"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "taskType"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "status"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "search"), "");
                                }), getPrepared, routeName$15, loadRouteRenderer$15, intent);
                  }),
                children: []
              },
              {
                path: "/tasks/:id",
                name: routeName$16,
                __$rescriptChunkName__: "Root__TaskDetails_route_renderer",
                loadRouteRenderer: loadRouteRenderer$16,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$16, loadRouteRenderer$16, makePrepareProps$16, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$16, (function (pathParams, queryParams) {
                                  return "Root__TaskDetails:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "");
                                }), getPrepared, routeName$16, loadRouteRenderer$16, intent);
                  }),
                children: []
              }
            ]
          }];
}

export {
  make ,
}
/* loadedRouteRenderers Not a pure module */
