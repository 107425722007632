// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";

function PendingIndicatorBar(props) {
  return JsxRuntime.jsx("div", {
              children: props.pending ? JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  className: "animate-progress w-full h-full bg-brand origin-left-right"
                                }),
                            className: "h-1 w-full overflow-hidden animate-fade-in"
                          }),
                      className: "w-full"
                    }) : null,
              className: "fixed left-0 top-0 w-full pointer-events-none z-20"
            });
}

var make = PendingIndicatorBar;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
