// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as ClerkReact from "@clerk/clerk-react";

function SignInPage(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ClerkReact.SignIn, {
                    signUpUrl: "/signup",
                    redirectUrl: "/"
                  }),
              className: "w-screen h-screen grid place-items-center"
            });
}

var make = SignInPage;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
