// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  if (str === "IN_PROGRESS" || str === "DONE" || str === "TO_DO") {
    return str;
  }
  
}

function serialize(s) {
  return s;
}

var defaultValue = "TO_DO";

export {
  defaultValue ,
  parse ,
  serialize ,
}
/* No side effect */
